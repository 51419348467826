import Haikunator from 'haikunator'
import { CustomerProfile } from '@lib/clients'
import { LOCAL_STORAGE_KEYS } from './consts'
import { Nullable } from '@lib/engine-types'

interface CustomerInformation {
  tenant_name: string
  time_created: number
  redirect_url: string
  customer: Nullable<CustomerProfile>
}

export function generateHaikunatorName() {
  return new Haikunator().haikunate()
}

export const storeCustomerData = (data: CustomerInformation) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO, JSON.stringify(data))
}

export const clearCustomerStoredData = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO)
  window.location.reload()
}
