import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import TenantCreationGlobalComplex from '@components-complex/tenant-creation-global/TenantCreationComplex'
import Head from 'next/head'

function TenantCreationPage() {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon-global.ico" />
      </Head>
      <TenantCreationGlobalComplex />
    </>
  )
}

TenantCreationPage.pageMeta = {
  metaTitle: 'Create tenant',
  metaDescription: 'Create tenant',
  metaKeywords: '',
  path: '/tenant-creation',
  layout: LayoutConstant.Empty,
}

export default TenantCreationPage
